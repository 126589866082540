import React, { useState } from 'react'
import cn from 'classnames'
import { useMenuState, Menu, MenuItem, MenuButton } from 'reakit/Menu'
import { motion } from 'framer-motion'
import { Icon, Text } from '@cmp'

export interface DropdownProps {
  title?: string
  className?: string
  items: {
    title: string
    onClick?: () => void
    disabled?: boolean
  }[]
}

export const Dropdown = ({ title = '', className, items }: DropdownProps) => {
  const menu = useMenuState({ animated: true })
  const [hover, setHover] = useState(false)

  const contentVariants = {
    visible: { opacity: 1, height: 'auto', transition: { type: 'tween' } },
    hidden: { opacity: 0, height: 0 },
  }
  const arrowVariants = {
    visible: { rotate: -45 },
    hidden: { rotate: 45 },
    hover: { translateX: 1, translateY: 3 },
  }
  return (
    <span
      className={cn(
        className,
        'flex relative text-left border-t border-l h-14 border-r w-5/12 md:w-52 items-center leading-none transition-colors border-current text-current font-bold',
        {
          'border-b focus:bg-yellow-400 hover:bg-yellow-400 focus:bg-opacity-20 hover:bg-opacity-20': !menu.visible,
          'bg-white bg-opacity-20 z-50': menu.visible,
        },
      )}
    >
      <MenuButton
        className='flex flex-row items-center justify-between w-full h-full pl-6 pr-4 uppercase focus:outline-none'
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
        {...menu}
      >
        <Text variant='sm' weight='bold'>
          {title}
        </Text>
        <motion.span
          animate={[menu.visible ? 'visible' : 'hidden', hover ? 'hover' : '']}
          variants={arrowVariants}
          className='inline-block ml-auto'
        >
          <Icon name='Arrow' className='w-4 h-auto' />
        </motion.span>
      </MenuButton>
      <Menu
        {...menu}
        aria-label={title}
        as={motion.div}
        initial='hidden'
        animate={menu.visible ? 'visible' : 'hidden'}
        variants={contentVariants}
        onAnimationComplete={() => {
          menu.stopAnimation()
        }}
        className='z-20 overflow-hidden border-b border-l border-r border-current fullParentWidth focus:outline-none'
        style={{ top: '-1px', left: '-1px' }}
      >
        {items.map((item, idx) => (
          <MenuItem
            key={idx}
            {...menu}
            onClick={() => {
              menu.hide()
              item.onClick?.()
            }}
            className={cn(
              'w-full z-30 block text-left pl-7.5 py-2 relative group transition-colors focus:outline-none leading-6 bg-white hover:bg-opacity-100 focus:bg-opacity-100',
              { 'text-dark-400': !item.disabled },
              { 'pointer-events-none text-dark-100': item.disabled },
            )}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </span>
  )
}

export default Dropdown
